<template>
  <div class="trumpet">
    <div class="flex-nowrap-space-between">
      <div class="flex-nowrap-flex-start">
        <div class="list-name">
          小号申请列表
        </div>
        <div class="ml-20">
          <!-- <el-button size="medium">
                        <icon-font type="icon-copy-4-05" class="trigger"/>
                        条件筛选
                    </el-button> -->
        </div>
      </div>
      <div>
        <div class="font-refresh-out flex-nowrap-center-center" @click="getWorkOrderList(true)">
          <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
          <i v-else class="el-icon-loading loding-color"></i>
        </div>
      </div>
    </div>
    <div class="mt-20">
      <el-table class="eltable" v-loading="loading" ref="multipleTable" size="mini" :data="countList"
        tooltip-effect="dark" style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}"
        row-key="id" max-height="500" default-expand-all :row-style="{height:'40px'}" :cell-style="{padding:'0px'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column fixed label="企业名称">
          <template #default="scope">{{ scope.row.company }}</template>
        </el-table-column>
        <el-table-column fixed label="用户名">
          <template #default="scope">{{ scope.row.username }}</template>
        </el-table-column>
        <el-table-column fixed label="服务商">
          <template
            #default="scope">{{ amountList[scope.row.amountId]==null?'未匹配到该服务商':amountList[scope.row.amountId] }}</template>
        </el-table-column>
        <el-table-column label="申请数量">
          <template #default="scope">{{ regionNum(scope.row.region) }}</template>
        </el-table-column>
        <el-table-column label="申请成功数量">
          <template #default="scope">{{ scope.row.needsSuccess == null?0:scope.row.needsSuccess }}</template>
        </el-table-column>
        <el-table-column label="线路模式">
          <template #default="scope">{{ scope.row.mode == 0?'AXB模式':'回拨模式' }}</template>
        </el-table-column>
        <el-table-column label="资费(元/分钟)">
          <template #default="scope">{{ scope.row.expenses }}</template>
        </el-table-column>
        <el-table-column label="分布范围">
          <template #default="scope">
            <div>
              <el-popover :width="100" trigger="click">
                <template #reference>
                  <div class="flex-nowrap-flex-start">
                    <div v-if="regionName(scope.row.region)[0] == null">--</div>
                    <div v-else>{{ regionName(scope.row.region)[0][0]+'/'+regionName(scope.row.region)[0][1] }}</div>
                    <div class="font-color-blue ml-10">{{ '('+regionName(scope.row.region).length+')' }}</div>
                    <div class="flex-nowrap-center-center ml-10">
                      <icon-font type="icon-chakan" class="img-icon" />
                    </div>
                  </div>
                </template>
                <div class="name-show-box" v-if="regionName(scope.row.region)[0] != null">
                  <div v-for="(name,n) in regionName(scope.row.region)" :key="n">
                    <div class="name-box">{{ name[0]||'--'+'/'+name[1] }}</div>
                  </div>
                </div>
                <div class="name-show-box" v-else>--</div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" label="申请时间 ">
          <template #default="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
        <el-table-column width="150" label="审核时间 ">
          <template #default="scope">{{ scope.row.updateTime }}</template>
        </el-table-column>
        <el-table-column fixed="right" label="审核状态">
          <template #default="scope">
            <div v-if="scope.row.status == 0 " class="tag-box waiting-color">待审核</div>
            <div v-if="scope.row.status == 1 " class="tag-box through-color">审核通过</div>
            <div v-if="scope.row.status == 2 " class="tag-box refuse-color">审核拒绝</div>
            <div v-if="scope.row.status == 3 " class="tag-box undo-color">已撤销</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template #default="scope">
            <div v-if="scope.row.status == 0 " class="img-icon font-color-blue" @click="audit(scope.row)">
              <icon-font type="icon-chakan" />
              审核
            </div>
            <div v-if="scope.row.status == 1 " class="img-icon font-color-blue" @click="audit(scope.row)">
              <icon-font type="icon-chakan" />
              查看备注
            </div>
            <div v-if="scope.row.status == 2 " class="img-icon font-color-blue" @click="audit(scope.row)">
              <icon-font type="icon-chakan" />
              查看原因
            </div>
            <div v-if="scope.row.status == 3 " class="img-icon font-color-blue" @click="audit(scope.row)">
              <icon-font type="icon-chakan" />
              查看详情
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-nowrap-flex-end p-20">
        <el-pagination v-model:currentPage="currentPage" :page-sizes="[10, 20, 50, 100]"
          :page-size="searchFrom.pageSize" background layout="total, sizes, prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <el-dialog v-model="centerDialogVisible" title="申请小号分配审核详情" :close-on-click-modal="false" :destroy-on-close="true"
      width="30%">
      <div class="p-0-20">
        <el-form label-position="left" ref="form" :model="form" :rules="rules" label-width="130px"
          :hide-required-asterisk="true">
          <el-form-item label="服务商：">
            <div>
              {{ amountList[form.amountId ]==null?'未匹配到该服务商':amountList[form.amountId] }}
            </div>
          </el-form-item>
          <el-form-item label="线路模式：">
            <div>
              {{ form.mode == 0?'AXB模式':'回拨模式' }}
            </div>
          </el-form-item>
          <el-form-item label="资费选择：">
            <div>
              {{ form.expenses }}
            </div>
          </el-form-item>
          <el-form-item label="小号分布/数量:">
            <div v-for="(num,n) in JSON.parse(form.region)" :key="n" class="flex-nowrap-flex-start">
              <div v-if="num.name == null">--</div>
              <div v-else>{{ num.name[0]+'/'+num.name[1] }}</div>
              <div class="ml-20">{{ num.num }}个小号</div>
            </div>
          </el-form-item>
          <el-form-item label="申请成功数量:" v-if="form.status != 0">
            <div>
              {{form.needsSuccess}}
            </div>
          </el-form-item>
          <el-form-item label="审核状态:" v-if="form.status != 0">
            <div class="flex-nowrap-flex-start tag-out-box">
              <div v-if="form.status == 0 " class="tag-box waiting-color">待审核</div>
              <div v-if="form.status == 1 " class="tag-box through-color">审核通过</div>
              <div v-if="form.status == 2 " class="tag-box refuse-color">审核拒绝</div>
              <div v-if="form.status == 3 " class="tag-box undo-color">已撤销</div>
            </div>
          </el-form-item>
          <el-form-item label="审核备注:" v-if="form.status != 0">
            <div>
              {{ form.remark }}
            </div>
          </el-form-item>
          <el-form-item label="申请成功数量:" v-if="form.status == 0" prop="needsSuccess">
            <el-input v-model.trim="form.needsSuccess" size="medium" placeholder="请输入申请成功数量" />
          </el-form-item>
          <el-form-item label="审核备注:" v-if="form.status == 0" prop="remark">
            <el-input v-model="form.remark" size="medium" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
              placeholder="请输入审核备注">
            </el-input>
          </el-form-item>
          <el-form-item label="" v-if="form.status == 0">
            <div class="flex-nowrap-flex-end">
              <el-button class="button-cancel" size="medium" @click="refuse">拒绝</el-button>
              <el-button class="button-confirm" size="medium" @click="through">通过</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import amountts from '@/api/open/privacy/amount'
import numberOrders from '@/api/open/privacy/numberOrders'
export default {
  name: 'lineCard',
  components: {
    IconFont,
  },
  data() {
    return {
      total: 1,
      currentPage: 1,
      searchFrom: {
        page: 1,
        pageSize: 10,
      },
      centerDialogVisible: false,
      loading: true,
      countList: [],
      amountList: {},
      form: {},
      rules: {
        needsSuccess: [
          {
            required: true,
            message: '成功数量不能为空',
            trigger: 'blur',
          },
        ],
        remark: [
          {
            required: true,
            message: '审核备注不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {
    this.getAmountType()
    this.getWorkOrderList(true)
  },
  methods: {
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        res.data.forEach((item, i) => {
          this.amountList[item.id] = item.name
        })
      })
    },
    getWorkOrderList(type) {
      this.loading = type
      numberOrders
        .list({
          ...this.searchFrom,
        })
        .then((res) => {
          this.countList = res.data.records
          this.total = res.data.total
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val
      this.getWorkOrderList(true)
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val
      this.currentPage = val
      this.getWorkOrderList(true)
    },
    // 过滤求值
    regionNum(list) {
      if (list == null) {
        return list
      } else {
        let otherList = JSON.parse(list),
          numberEnd = null
        otherList.forEach((other, o) => {
          numberEnd = numberEnd + other.num
        })
        return numberEnd
      }
    },
    regionName(list) {
      if (list == null) {
        return []
      } else {
        let otherList = JSON.parse(list),
          name = []
        otherList.forEach((other, o) => {
          if (other.name == null) {
            return
          } else {
            name.push(other.name)
          }
        })
        return name
      }
    },
    // 审核
    audit(row) {
      this.form = row
      this.centerDialogVisible = true
    },
    // 通过
    through() {
      this.$refs.form.validateField('needsSuccess', (errorMessage) => {
        if (errorMessage == '') {
          this.form.status = 1
          numberOrders
            .edit({
              ...this.form,
            })
            .then((res) => {
              this.$notify({
                title: '成功',
                message: '已通过！',
                type: 'success',
              })
              this.centerDialogVisible = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 拒绝
    refuse() {
      this.$refs['form'].validateField('remark', (errorMessage) => {
        if (errorMessage == '') {
          this.form.status = 2
          numberOrders
            .edit({
              ...this.form,
            })
            .then((res) => {
              this.$notify({
                title: '成功',
                message: '已拒绝！',
                type: 'success',
              })
              this.centerDialogVisible = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.trumpet {
  padding-right: 1.125rem;
}
.list-name {
  font-size: 16px;
  font-weight: 700;
  color: #131523;
}
.font-refresh {
  font-size: 20px;
  color: #ffffff;
}
.font-refresh-out {
  width: 30px;
  height: 30px;
  background: #637dff;
  border-radius: 2px;
  cursor: pointer;
}
.name-show-box {
  max-height: 200px;
  overflow: overlay;
}
.name-box {
  text-align: center;
}
.img-icon {
  cursor: pointer;
}
.through-font {
  color: #13bc13;
}
.refuse-font {
  color: #ff2d2d;
}
.tag-box {
  width: 70px;
  height: 24px;
  text-align: center;
  border-radius: 12px;
  line-height: 24px;
}
.waiting-color {
  color: #f99600;
  background: #ffe7c4;
}
.through-color {
  color: #13bc13;
  background: #c8f1dc;
}
.refuse-color {
  color: #ff2d2d;
  background: #ffe6e6;
}
.undo-color {
  color: #999999;
  background: #ececec;
}
.tag-out-box {
  height: 40px;
}
</style>