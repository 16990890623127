import { Request } from '@/http/request'

export function add (parameter: any){
  return Request.axiosInstance.post('/open/number-order/add', parameter)
}
export function list (parameter: any){
  return Request.axiosInstance.post('/open/number-order/list', parameter)
}
export function edit (parameter: any){
  return Request.axiosInstance.post('/open/number-order/edit', parameter)
}


export default { add, list, edit }